import React from "react";
import { graphql, PageProps } from "gatsby";
import { GetRibbonQuery } from "../../graphql-types";
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/SEO";

const RibbonGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  @media (max-width: 600px) {
    .mark {
      font-size: 2.5rem;
    }
    .gatsby-image-wrapper {
      width: 73vw;
    }
  }
`;

interface ISingleRibbonPage extends PageProps {
  data: GetRibbonQuery;
}

export default function SingleRibbonPage(props: ISingleRibbonPage) {
  const ribbon = props.data.ribbon;
  return (
    <>
      <SEO title={ribbon.name} image={ribbon.image?.asset?.fluid?.src} />
      <RibbonGrid>
        <Img fluid={ribbon.image.asset.fluid} />
        <div>
          <h2 className="mark">{ribbon.name}</h2>
          <ul>
            {ribbon.models.map((model) => (
              <li key={model.id}>{model.name}</li>
            ))}
          </ul>
        </div>
      </RibbonGrid>
    </>
  );
}

export const query = graphql`
  query GetRibbon($slug: String!) {
    ribbon: sanityRibbon(slug: { current: { eq: $slug } }) {
      name
      id
      price
      slug {
        current
      }
      models {
        name
        id
        baby
      }
      image {
        asset {
          fluid(maxHeight: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
